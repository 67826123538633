<template>
  <div
    class="d-flex align-center justify-center flex-column"
    style="height: 80vh"
  >
    <div>
      <v-icon size="48">
        mdi-alert-circle-outline
      </v-icon>
    </div>
    <div class="text-h4 mt-6">
      {{ $t('FunctionNotActiv') }}
    </div>
    <div class="text-body-1 mt-2 text--secondary">
      {{ $t('PleaseActivateProduct') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoProductPage'
}
</script>

<style scoped>

</style>
