<template>
  <v-dialog
    v-model="ifLoading"
    max-width="400px"
  >
    <v-card
      class="d-flex align-center justify-center flex-column"
      style="height: 300px"
    >
      <div class="mb-10 text-h3 font-weight-bold">
        {{ $t('waitAMoment') }}
      </div>
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: ['justWait'],
  data: function () {
    return {
      ifLoading: false
    }
  },
  watch: {
    justWait (val) {
      if (val) {
        this.ifLoading = true
      } else {
        this.ifLoading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
